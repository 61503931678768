import * as Sentry from "@sentry/nuxt";

Sentry.init({
    // If set up, you can use your runtime config here
    // dsn: useRuntimeConfig().public.sentry.dsn,
    dsn: "https://b608b12f4b0bfe7d8ec9c37ec9c4340c@o921823.ingest.us.sentry.io/4507616041566208",
    integrations: [
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration()
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
});